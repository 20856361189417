import React from "react";

import Layout from "../components/layout";
import { Seo } from "../components/seo";

export const Head = () => <Seo title="404: Not found" />;

const NotFoundPage = () => (
  <Layout>
    <h1>404: Not Found</h1>
    <p>This page does not exist. Sorry bro!</p>
  </Layout>
);

export default NotFoundPage;
